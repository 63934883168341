.header {
	background-color: whitesmoke !important;

	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #e0e0e0;

	z-index: 1;

	box-shadow: 0px 5px 5px #dadada;
}

.content {
	padding: 10px;
	padding-top: 50px;

	background-color: white;
}